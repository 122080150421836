import React, { lazy } from 'react';
import { Routes, Route, Redirect } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Header from './layouts/header';
import ProtectedSuperAdminRoutes from './privateRoutes/superAdmin';
import ProtectedAdminRoutes from './privateRoutes/admin';
import ProtectedLandlordRoutes from './privateRoutes/landlord';
import ProtectedTenantRoutes from './privateRoutes/tenant';
import TenantLandlordList from './pages/tenant/landlordlist';
const LinkedAccounts = lazy(() => import('./pages/landlord/LinkedAccounts'));
const SaveCard = lazy(() =>
  import('./pages/landlord/Checkout/CardAdded/index')
);
const Checkout = lazy(() => import('./pages/landlord/Checkout/Checkout'));
const EmptyContainer = lazy(() => import('./layouts/header'));
const LoginPage = lazy(() => import('./pages/common/Login'));
const ForgotPage = lazy(() => import('./pages/common/ForgotPassword'));
const HomePage = lazy(() => import('./pages/common/Home'));
const SignupPage = lazy(() => import('./pages/common/SignupPage'));
const ProfilePage = lazy(() => import('./pages/common/Profile'));
const AdminLandlordList = lazy(() => import('./pages/admin/landlordList'));
const LandlordListEdit = lazy(() => import('./pages/admin/landlordListEdit'));
const TenantList = lazy(() => import('./pages/admin/TenantList'));
const TenantListEdit = lazy(() => import('./pages/admin/TenantListEdit'));
const PlanList = lazy(() => import('./pages/admin/PlanList'));
const AddNewPlan = lazy(() => import('./pages/admin/AddNewPlan'));
const PlanEdit = lazy(() => import('./pages/admin/PlanEdit'));
const TenantDashboard = lazy(() => import('./pages/tenant/TenantDashboard'));
const LandlordTenantList = lazy(() => import('./pages/landlord/TenantList'));
const PaymentDue = lazy(() => import('./pages/tenant/PaymentDue'));
const PaymentDetails = lazy(() => import('./pages/tenant/PaymentDetails'));
const ServiceRequest = lazy(() => import('./pages/common/service-request'));
const CreateServiceRequest = lazy(() =>
  import('./pages/tenant/service-request')
);

const EditServiceRequest = lazy(() =>
  import('./pages/common/service-request/edit-service')
);

const SuperAdminDashboard = lazy(() => import('./pages/superadmin/Dashboard'));
const SuperAdminTransaction = lazy(() =>
  import('./pages/superadmin/transactions')
);
const SuperAdminViewAdmin = lazy(() =>
  import('./pages/superadmin/AdminViewOrEdit')
);
const AdminDashboard = lazy(() => import('./pages/admin/Dashboard'));
const ContactedList = lazy(() => import('./pages/superadmin/contactedList'));
const EditList = lazy(() => import('./pages/superadmin/EditList/index'));

// Landlord Routes
const AddProperty = lazy(() => import('./pages/landlord/AddProperty'));
const LandlordTenantEdit = lazy(() => import('./pages/landlord/TenantEdit'));
const LandlordDashboard = lazy(() =>
  import('./pages/landlord/landlordDashboard')
);

const SubscriptionList = lazy(() =>
  import('./pages/landlord/SubscriptionList')
);
// property routes
const PropertyList = lazy(() => import('./pages/common/PropertyList'));
const LandlordPropertyDetail = lazy(() =>
  import('./pages/common/PropertyDetail')
);

const TransactionPage = lazy(() => import('./pages/admin/TransactionList'));
const TransactionDetailsPage = lazy(() =>
  import('./pages/common/TransactionDetails')
);

const TransactionListPage = lazy(() =>
  import('./pages/landlord/TransactionList')
);

const PropertyCharges = lazy(() => import('./pages/common/PropertyCharges'));

const TransactionListPageTenant = lazy(() =>
  import('./pages/tenant/TransactionList')
);

const TransactionDetailsPageTenant = lazy(() =>
  import('./pages/common/TransactionView')
);
const OrderDueDetailsPageForLandlord = lazy(() =>
  import('./pages/landlord/TransactionDetails')
);

// const location = useLocation();
const WebRoutes = ({ type, setType, showNotification }) => {
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  return (
    <AnimatePresence>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot" element={<ForgotPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/landlordlist/tenant" element={<TenantLandlordList />} />
        <Route element={<ProtectedSuperAdminRoutes />}>
          <Route element={<Header />}>
            <Route path="/superadmin" element={<SuperAdminDashboard />} />
            <Route
              path="/superadmin/transaction"
              element={<SuperAdminTransaction />}
            />
            <Route
              path="/superadmin/admin/:id"
              element={<SuperAdminViewAdmin />}
            />
            <Route path="/superadmin/contact" element={<ContactedList />} />
            <Route
              path="/superadmin/contact/edit/:formId"
              element={<EditList />}
            />
            <Route path="/superadmin/profile" element={<ProfilePage />} />
          </Route>
        </Route>
        <Route element={<ProtectedAdminRoutes />}>
          <Route element={<Header />}>
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/admin/tenant" element={<TenantList />} />
            <Route
              path="/admin/tenant/edit/:formId"
              element={<TenantListEdit />}
            />
            <Route path="/admin/landlord" element={<AdminLandlordList />} />
            <Route
              path="/admin/landlord/edit/:formId"
              element={<LandlordListEdit />}
            />
            <Route
              path="/admin/property/charges/:propertyId"
              element={<PropertyCharges />}
            />
            <Route path="/admin/plan/" element={<PlanList />} />
            <Route path="/admin/plan/add-plan" element={<AddNewPlan />} />
            <Route path="/admin/plan/edit/:planId" element={<PlanEdit />} />
            <Route path="/admin/property" element={<PropertyList />} />
            <Route
              path="/admin/add-property/:propertyId"
              element={<AddProperty />}
            />
            <Route
              path="/admin/property/:propertyId"
              element={<LandlordPropertyDetail />}
            />
            <Route path="/admin/profile" element={<ProfilePage />} />
            <Route path="/admin/transaction" element={<TransactionPage />} />
            <Route
              path="/admin/transaction/:transactionId"
              element={<TransactionDetailsPage />}
            />
          </Route>
        </Route>
        <Route element={<ProtectedLandlordRoutes />}>
          <Route element={<Header />}>
            <Route path="/landlord" element={<LandlordDashboard />} />
            <Route path="/landlord/tenant" element={<LandlordTenantList />} />
            <Route path="/landlord/add-property" element={<AddProperty />} />
            <Route
              path="/landlord/service-request"
              element={<ServiceRequest />}
            />
            <Route
              path="/landlord/service-request/:id"
              element={<EditServiceRequest />}
            />
            <Route
              path="/landlord/add-property/:propertyId"
              element={<AddProperty />}
            />
            <Route path="/landlord/property" element={<PropertyList />} />
            <Route
              path="/landlord/property/:propertyId"
              element={<LandlordPropertyDetail />}
            />
            <Route
              path="/landlord/property/charges/:propertyId"
              element={<PropertyCharges />}
            />
            <Route
              path="/landlord/tenant/edit/:formId"
              element={<LandlordTenantEdit />}
            />
            <Route
              path="/landlord/subscriptions"
              element={<SubscriptionList />}
            />
            <Route
              path="/landlord/linked-account"
              element={<LinkedAccounts />}
            />
            <Route path="/landlord/checkout" element={<Checkout />} />
            <Route path="/landlord/save-card" element={<SaveCard />} />
            <Route path="/landlord/profile" element={<ProfilePage />} />
            <Route
              path="/landlord/transaction"
              element={<TransactionListPage />}
            />
            {/* subscription paid to admin transaction details page */}
            <Route
              path="/landlord/transaction/:transactionId"
              element={<TransactionDetailsPage />}
            />
            {/* rent  payment received from tenant transaction details page */}
            <Route
              path="/landlord/order-transaction/:transactionId"
              element={<TransactionDetailsPageTenant />}
            />
            {/* rent  payment due edit details page*/}
            <Route
              path="/landlord/due-order/:orderId"
              element={<OrderDueDetailsPageForLandlord />}
            />
          </Route>
        </Route>
        <Route element={<ProtectedTenantRoutes />}>
          <Route element={<Header />}>
            <Route path="/tenant" element={<TenantDashboard />} />
            <Route path="/tenant/landlord" element={<TenantLandlordList />} />
            <Route
              path="/tenant/service-request"
              element={<ServiceRequest />}
            />
            <Route
              path="/tenant/service-request/create"
              element={<CreateServiceRequest />}
            />
            <Route
              path="/tenant/service-request/:id"
              element={<EditServiceRequest />}
            />
            <Route path="/tenant/property" element={<PropertyList />} />
            <Route path="/tenant/rent" element={<PaymentDue />} />
            <Route path="/tenant/rent/:orderId" element={<PaymentDetails />} />
            <Route
              path="/tenant/property/:propertyId"
              element={<LandlordPropertyDetail />}
            />
            <Route
              path="/tenant/property/charges/:propertyId"
              element={<PropertyCharges />}
            />
            <Route path="/tenant/profile" element={<ProfilePage />} />
            <Route
              path="/tenant/transaction"
              element={<TransactionListPageTenant />}
            />
            <Route
              path="/tenant/transaction/:transactionId"
              element={<TransactionDetailsPageTenant />}
            />
          </Route>
        </Route>
      </Routes>
    </AnimatePresence>
  );
};

export default WebRoutes;
